import React from 'react';

export const TOP_WRAPPER_ID = 'contentsWrapper';

export const scrollToWrapperTop = () => {
  document.getElementById(TOP_WRAPPER_ID)?.scrollIntoView();
};

export const getTopWrapperSize = () => {
  return {
    width: document.getElementById(TOP_WRAPPER_ID)?.scrollWidth,
    height: document.getElementById(TOP_WRAPPER_ID)?.scrollHeight,
  };
};

export const TopWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <div id={TOP_WRAPPER_ID}>{children}</div>
);
